import { Grid2, Link, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  abbreviateCount,
  formatPercentage,
} from '@social-garden/utils/helpers.ts';
import ChannelStatisticItem from './ChannelStatisticItem.tsx';

export interface TiktokChannelStatisticsData {
  displayName: string;
  url: string;
  followerCount: number;
  videoCount: number;
  averageEngagementRate?: number | null;
  averageReach?: number | null;
}

interface TiktokChannelStatisticsProps {
  data: TiktokChannelStatisticsData;
}

export default function TiktokChannelStatistics({
  data,
}: TiktokChannelStatisticsProps) {
  const { t } = useTranslation(['common']);

  return (
    <Stack spacing={1}>
      <Stack direction="row">
        <Link href={data.url} target="_blank">
          {data.displayName}
        </Link>
      </Stack>
      <Grid2 container spacing={2}>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.followerCount', {
              count: data.followerCount,
            })}
            value={abbreviateCount(data.followerCount)}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.averageEngagementRate')}
            value={formatPercentage(data.averageEngagementRate || 0)}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.averageReach')}
            value={abbreviateCount(data.averageReach || 0)}
          />
        </Grid2>
        <Grid2 size="auto">
          <ChannelStatisticItem
            label={t('common:channel.videoCount', {
              count: data.videoCount,
            })}
            value={abbreviateCount(data.videoCount)}
          />
        </Grid2>
      </Grid2>
    </Stack>
  );
}
